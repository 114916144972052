import { Component, OnInit } from "@angular/core";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, ActivatedRoute, GuardsCheckStart } from '@angular/router';
import { DropdownService } from './services/content/dropdown.service';
import { first, filter, map, switchMap } from 'rxjs/operators';
import { AuthenticationService } from './services/user/auth.service';
import { of } from 'rxjs';
import { SearchService } from './services/content/search.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  title = 'my-dream-app';


  constructor(private router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private authService: AuthenticationService) {

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        window.scrollTo(0, 0);
      }

    });
  }
  ngOnInit() {

  }
}
