
import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin/admin.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Page } from 'src/app/models/page';
import { round } from '@amcharts/amcharts4/.internal/core/utils/Math';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})

export class SubscriptionsComponent implements OnInit {

  loading = false;
  userSubscriptionsData = [];
  chooseUser;

  page = new Page();
  filters = {
    search: ""
  };


  constructor(
    private adminService: AdminService,
    private alert: ToastrService,
    private modalService: NgbModal
  ) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit() {
    // this.userSubscriptionsData =
    //   [{
    //     user_name: 'udhayakumar',
    //     company: 'Optisol',
    //     plan_name: 'Testing',
    //     auto_renewal: 'Yes',
    //     expiry_date: '2020-08-10',
    //     days_remaining: '19',
    //   },
    //   {
    //     user_name: 'Ramesh',
    //     company: 'Joist',
    //     plan_name: 'Testing 123',
    //     auto_renewal: 'No',
    //     expiry_date: '2020-10-10',
    //     days_remaining: '10',
    //   },
    //   {
    //     user_name: 'Rajesh',
    //     company: 'Google',
    //     plan_name: 'Testing',
    //     auto_renewal: 'Yes',
    //     expiry_date: '2020-09-10',
    //     days_remaining: '25',
    //   }

    //   ];
    this.getSubscriptions({ offset: 0 });
  }

  getSubscriptions(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    let postdata = {
      skip: this.page.pageNumber * this.page.size,
      take: this.page.size,
      filters: this.filters
    }
    this.adminService
      .getSubscriptions(postdata)
      .pipe(first())
      .subscribe(data => {
        if (data) {
          this.loading = false;
          this.page.totalElements = data.total;
          this.page.totalPages = round((data.total / this.page.size));
          this.userSubscriptionsData = data.subscription_data;
        }
      }, error => {
        this.loading = false;
        this.alert.error(error.error.message);
      });
  }



}

