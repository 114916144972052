import { Injectable } from '@angular/core';
import { AuthenticationService } from '../user/auth.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    public GlobalSearchStrSubject: BehaviorSubject<any>;
    public GlobalSearchStr: Observable<any>;
    constructor(private http: HttpClient, private router: Router, private auth: AuthenticationService) {
        if (localStorage.getItem('globalSearchStr') != 'undefined') {
            this.GlobalSearchStrSubject = new BehaviorSubject<any>(
                localStorage.getItem('globalSearchStr')
            );
            this.GlobalSearchStr = this.GlobalSearchStrSubject.asObservable();
        }
    }

    /*
     * get search result list api
     * @returns data
     * @memberof EmployeeService
     */
    getSearchResults(postdata) {
        const url = `${environment.restUrl}/api/search/getResults`;
        postdata.user_id = this.auth.currentUserValue.id;
        const tokenDetail = this.auth.currentUserTokenValue;

        let opts = {};
        if (tokenDetail != null) {
            opts = {
                headers: new HttpHeaders({
                    'Authorization': tokenDetail.token_type + ' ' + tokenDetail.access_token
                })
            }
        }
        return this.http.post<any>(url, postdata, opts).pipe(
            map((data: any) => {
                return data;
            })
        );

    }
}