import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin/admin.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Page } from 'src/app/models/page';
import { round } from '@amcharts/amcharts4/.internal/core/utils/Math';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {

  loading = false;
  userAccountsData = [];
  chooseUser;
  send_reminder;
  disable_account;
  viewMode = "";

  page = new Page();
  filters = {
    search: ""
  };
  ColumnMode = ColumnMode;

  constructor(
    private adminService: AdminService,
    private alert: ToastrService,
    private modalService: NgbModal
  ) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit() {
    this.getAccounts({ offset: 0 });
  }
  getAccounts(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    let postdata = {
      skip: this.page.pageNumber * this.page.size,
      take: this.page.size,
      filters: this.filters
    }
    this.adminService
      .getAccounts(postdata)
      .pipe(first())
      .subscribe(data => {
        if (data) {
          this.loading = false;
          this.page.totalElements = data.total;
          this.page.totalPages = round((data.total / this.page.size));
          this.userAccountsData = data.users_data;
        }
      }, error => {
        this.loading = false;
        this.alert.error(error.error.message);
      });
  }

  userActiveStatusChange(user, activeStatus) {

    this.modalService.dismissAll(); 
    let postdata = {
      status_change_id: user.id,
      change_status: activeStatus,
    }
    this.adminService
      .userActiveStatusChange(postdata)
      .pipe(first())
      .subscribe(data => {
        if (data) {
          this.loading = false;
          this.alert.success(data.message);
          this.getAccounts({ offset: 0 });
        }
      }, error => {
        this.loading = false;
        this.alert.error(error.error.message);
      });
  }

  userDisableStatusChange(user, activeStatus) {

    this.modalService.dismissAll(); 
    let postdata = {
      status_change_id: user.id,
      change_disable_status:activeStatus,
    }
    this.adminService
      .userDisableStatusChange(postdata)
      .pipe(first())
      .subscribe(data => {
        if (data) {
          this.loading = false;
          this.alert.success(data.message);
          this.getAccounts({ offset: 0 });
        }
      }, error => {
        this.loading = false;
        this.alert.error(error.error.message);
      });
  }
  sendReminderEmail(user)
  {
    this.modalService.dismissAll(); 
    let postdata = {
      userDetails: user,
      id:user.id,
    }
    this.adminService
      .usersendReminderEmail(postdata)
      .pipe(first())
      .subscribe(data => {
        if (data) {
          this.loading = false;
          this.alert.success(data.message);
          this.getAccounts({ offset: 0 });
        }
      }, error => {
        this.loading = false;
        this.alert.error(error.error.message);
      });
  }
}
