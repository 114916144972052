
import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin/admin.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Page } from 'src/app/models/page';
import { round } from '@amcharts/amcharts4/.internal/core/utils/Math';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Validators, FormBuilder } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ColumnMode } from '@swimlane/ngx-datatable';


@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.scss']
})

export class SubscriptionPlansComponent implements OnInit {

  public Editor = ClassicEditor;


  loading = false;
  userSubscriptionPlansData = [];
  choosePlan;
  submitted = false;
  planForm: any;
  planEdit = false;
  page = new Page();
  filters = {
    search: ""
  };
  editorData;
  ColumnMode = ColumnMode;






  constructor(
    private adminService: AdminService,
    private alert: ToastrService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
  ) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit() {
    this.Editor.defaultConfig = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'insertTable',
          '|',
          'undo',
          'redo'
        ]
      },
    };

    this.getSubscriptionPlans({ offset: 0 });
    //employee form validation 
    this.planForm = this.formBuilder.group({
      id: [
        null,
        [
        ]
      ],
      stripe_plan_id: [
        null,
        [
        ]
      ],
      name: [
        '',
        [
          Validators.required,
          Validators.pattern("^[A-Za-z -]+$"),
          Validators.maxLength(22)
        ]
      ],
      no_of_days: [
        null,
        [
          Validators.required,
        ]
      ],
      no_of_employees: [
        0,
        [
          Validators.required
        ]
      ],
      price: [
        '',
        [
          Validators.required
        ]
      ],
      plan_type: [
        null,
        [
        ]
      ],
      plan_interval_count: [
        1,
        [
        ]
      ],
      description: [
        '',
        [
          Validators.required,
          Validators.maxLength(22)

        ]
      ],
      plan_detail: [
        '',
        [
          Validators.required,
        ]
      ],

      status: [
        '',
        [
          Validators.required,

        ]
      ]
    });
  }


  async onSubmit() {
    this.submitted = true;
    if (this.planForm.valid) {
      this.getPlanType(parseInt(this.plnFrm.no_of_days.value));
      let postdata = this.planForm.value;
      this.modalService.dismissAll();
      this.loading = true;
      this.adminService
        .subscriptionPlanSave(postdata)
        .pipe(first())
        .subscribe(data => {
          if (data) {
            this.loading = false;
            this.submitted = false;
            this.planForm.reset();
            this.alert.success(data.message);
            this.getSubscriptionPlans({ offset: 0 });
          }
        }, error => {
          this.loading = false;
          this.submitted = false;
          this.alert.error(error.error.message);
        });
    }
  }


  get plnFrm() {
    return this.planForm.controls;
  }

  getSubscriptionPlans(pageInfo) {
    this.loading = true;
    this.page.pageNumber = pageInfo.offset;
    let postdata = {
      skip: this.page.pageNumber * this.page.size,
      take: this.page.size,
      filters: this.filters
    }
    this.adminService
      .getSubscriptionPlans(postdata)
      .pipe(first())
      .subscribe(data => {
        if (data) {
          this.loading = false;
          this.page.totalElements = data.total;
          this.page.totalPages = round((data.total / this.page.size));
          this.userSubscriptionPlansData = data.subscription_plan_data;

        }
      }, error => {
        this.loading = false;
        this.alert.error(error.error.message);
      });
  }


  openPlanModal(content, plan) {
    this.planForm.controls['no_of_days'].enable();
    this.planForm.reset();
    this.planEdit = false;
    this.editorData = "";
    if (plan) {
      this.planEdit = true;
      this.choosePlan = plan;
      this.planForm.controls['id'].setValue(plan.id);
      this.planForm.controls['name'].setValue(plan.name);
      this.planForm.controls['plan_type'].setValue(plan.plan_type);
      this.planForm.controls['no_of_employees'].setValue(plan.no_of_employees);
      this.planForm.controls['price'].setValue(plan.price);
      this.planForm.controls['description'].setValue(plan.description);
      this.planForm.controls['no_of_days'].setValue(plan.no_of_days);
      this.planForm.controls['plan_interval_count'].setValue(plan.description);
      this.planForm.controls['status'].setValue(plan.status);
      this.planForm.controls['stripe_plan_id'].setValue(plan.stripe_plan_id);
      this.planForm.controls['plan_detail'].setValue(plan.plan_detail);
      this.editorData = plan.plan_detail;
      this.planForm.controls['no_of_days'].disable();
    }
    this.submitted = false;
    this.modalService.open(content, { windowClass: "Addnewemployee", backdrop: 'static' });
  }


  deletePlanModal(content, plan) {
    this.choosePlan = plan;
    this.modalService.open(content, { windowClass: "delete-employee" });
  }



  getPlanType(noOfDays) {

    switch (noOfDays) {
      case 30: {
        //statements; 
        this.planForm.controls['plan_type'].setValue('month');
        this.planForm.controls['plan_interval_count'].setValue(1);
        break;
      }
      case 90: {
        //statements; 
        this.planForm.controls['plan_type'].setValue('month');
        this.planForm.controls['plan_interval_count'].setValue(3);
        break;
      }
      case 180: {
        //statements; 
        this.planForm.controls['plan_type'].setValue('month');
        this.planForm.controls['plan_interval_count'].setValue(6);
        break;
      }
      case 365: {
        //statements; 
        this.planForm.controls['plan_type'].setValue('year');
        this.planForm.controls['plan_interval_count'].setValue(1);
        break;
      }
      default: {
        //statements;
        this.planForm.controls['plan_type'].setValue('month');
        this.planForm.controls['plan_interval_count'].setValue(1);
        break;
      }
    }
  }

  deleteSubscriptionPlan() {
    this.loading = true;
    let postdata = {
      plan_id: this.choosePlan.id,
      stripe_plan_id: this.choosePlan.stripe_plan_id
    }
    this.adminService
      .deleteSubscriptionPlan(postdata)
      .pipe(first())
      .subscribe(data => {
        if (data) {
          this.loading = false;
          this.alert.success(data.message);
          this.getSubscriptionPlans({ offset: 0 });

        }
      }, error => {
        this.loading = false;
        this.alert.error(error.error.message);
      });
  }
  special_char_validation(event)
  {   
     var k;  
     k = event.charCode;  //         k = event.keyCode;  (Both can be used)
     return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
  }
}


