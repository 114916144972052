import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";

import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { AuthGuard } from './services/user/auth-guard.service';
import { PermissionDeniedComponent } from './pages/permission-denied/permission-denied.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AccountsComponent } from './pages/accounts/accounts.component';
import { SubscriptionPlansComponent } from './pages/subscription-plans/subscription-plans.component';
import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component';

// import {} from './pages/settings/settings.module'
// import {} from './pages/employees/employees.module'
// import {} from './pages/projects/projects.module'
// import {} from './pages/staffings/staffings.module'



const routes: Routes = [
  {
    path: "",
    redirectTo: "dash",
    pathMatch: "full"
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "dash",
        loadChildren: "./pages/dash/dash.module#DashModule",
        canActivate: [AuthGuard]
      },
      

      {
        path: "permission-denied",
        component: PermissionDeniedComponent

      },
      {
        path: "accounts",
        component: AccountsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "subscriptions",
        component: SubscriptionsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "plans",
        component: SubscriptionPlansComponent,
        canActivate: [AuthGuard]
      },
    ]
  },

  
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "auth",
        loadChildren:
          "./layouts/auth-layout/auth-layout.module#AuthLayoutModule"
      }
    ]
  },

  {
    path: "**",
    redirectTo: "dash"
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
