import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../user/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(private http: HttpClient, private router: Router, private auth: AuthenticationService) { }


  /*
     * get accounts list api
     * @returns data
     * @memberof StaffingService
     */
  getAccounts(postdata) {
    const url = `${environment.restUrl}/api/admin/getAccounts`;
    postdata.user_id = this.auth.currentUserValue.id;

    const tokenDetail = this.auth.currentUserTokenValue;

    let opts = {};
    if (tokenDetail != null) {
      opts = {
        headers: new HttpHeaders({
          'Authorization': tokenDetail.token_type + ' ' + tokenDetail.access_token
        })
      }
    }
    return this.http.post<any>(url, postdata, opts).pipe(
      map((data: any) => {
        return data;
      })
    );

  }


  /*
     * get accounts list api
     * @returns data
     * @memberof StaffingService
     */
  getSubscriptions(postdata) {
    const url = `${environment.restUrl}/api/admin/getSubscriptions`;
    postdata.user_id = this.auth.currentUserValue.id;

    const tokenDetail = this.auth.currentUserTokenValue;

    let opts = {};
    if (tokenDetail != null) {
      opts = {
        headers: new HttpHeaders({
          'Authorization': tokenDetail.token_type + ' ' + tokenDetail.access_token
        })
      }
    }
    return this.http.post<any>(url, postdata, opts).pipe(
      map((data: any) => {
        return data;
      })
    );

  }


  /*
   * get subscription plans list api
   * @returns data
   * @memberof StaffingService
   */
  getSubscriptionPlans(postdata) {
    const url = `${environment.restUrl}/api/admin/getSubscriptionPlans`;
    postdata.user_id = this.auth.currentUserValue.id;

    const tokenDetail = this.auth.currentUserTokenValue;

    let opts = {};
    if (tokenDetail != null) {
      opts = {
        headers: new HttpHeaders({
          'Authorization': tokenDetail.token_type + ' ' + tokenDetail.access_token
        })
      }
    }
    return this.http.post<any>(url, postdata, opts).pipe(
      map((data: any) => {
        return data;
      })
    );

  }

  /*
   * delete subscription plan list api
   * @returns data
   * @memberof StaffingService
   */
  deleteSubscriptionPlan(postdata) {
    const url = `${environment.restUrl}/api/admin/deleteSubcriptionPlan`;
    postdata.user_id = this.auth.currentUserValue.id;

    const tokenDetail = this.auth.currentUserTokenValue;

    let opts = {};
    if (tokenDetail != null) {
      opts = {
        headers: new HttpHeaders({
          'Authorization': tokenDetail.token_type + ' ' + tokenDetail.access_token
        })
      }
    }
    return this.http.post<any>(url, postdata, opts).pipe(
      map((data: any) => {
        return data;
      })
    );

  }

  subscriptionPlanSave(postdata) {
    const url = `${environment.restUrl}/api/admin/subscriptionPlanSave`;
    postdata.user_id = this.auth.currentUserValue.id;

    const tokenDetail = this.auth.currentUserTokenValue;

    let opts = {};
    if (tokenDetail != null) {
      opts = {
        headers: new HttpHeaders({
          'Authorization': tokenDetail.token_type + ' ' + tokenDetail.access_token
        })
      }
    }
    return this.http.post<any>(url, postdata, opts).pipe(
      map((data: any) => {
        return data;
      })
    );

  }


  userActiveStatusChange(postdata) {
    const url = `${environment.restUrl}/api/admin/activeStatusChange`;
    postdata.user_id = this.auth.currentUserValue.id;

    const tokenDetail = this.auth.currentUserTokenValue;

    let opts = {};
    if (tokenDetail != null) {
      opts = {
        headers: new HttpHeaders({
          'Authorization': tokenDetail.token_type + ' ' + tokenDetail.access_token
        })
      }
    }
    return this.http.post<any>(url, postdata, opts).pipe(
      map((data: any) => {
        return data;
      })
    );

  }

  userDisableStatusChange(postdata) {
    const url = `${environment.restUrl}/api/admin/disableStatusChange`;
    postdata.user_id = this.auth.currentUserValue.id;

    const tokenDetail = this.auth.currentUserTokenValue;

    let opts = {};
    if (tokenDetail != null) {
      opts = {
        headers: new HttpHeaders({
          'Authorization': tokenDetail.token_type + ' ' + tokenDetail.access_token
        })
      }
    }
    return this.http.post<any>(url, postdata, opts).pipe(
      map((data: any) => {
        return data;
      })
    );

  }

  usersendReminderEmail(postdata) {
    const url = `${environment.restUrl}/api/admin/sendReminderEmail`;
    postdata.user_id = postdata.id;
    const tokenDetail = this.auth.currentUserTokenValue;
    let opts = {};
    if (tokenDetail != null) {
      opts = {
        headers: new HttpHeaders({
          'Authorization': tokenDetail.token_type + ' ' + tokenDetail.access_token
        })
      }
    }
    return this.http.post<any>(url, postdata, opts).pipe(
      map((data: any) => {
        return data;
      })
    );

  }

}