import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../../models/user';
import { Router, ActivatedRoute } from '@angular/router';
import { UserToken } from 'src/app/models/usertoken';

@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {

    public currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<User>;

    public currentUserTokenSubject: BehaviorSubject<any>;
    public currentUserToken: Observable<UserToken>;


    public currentUserRoleSubject: BehaviorSubject<any>;
    public currentUserRole: Observable<any>;


    public currentUserRolePermissionsSubject: BehaviorSubject<any>;
    public currentUserRolePermissions: Observable<any>;

    constructor(private http: HttpClient, private router: Router) {

        if (localStorage.getItem('currentUser') != 'undefined') {
            this.currentUserSubject = new BehaviorSubject<User>(
                JSON.parse(localStorage.getItem('currentUser') as string)
            );
            this.currentUser = this.currentUserSubject.asObservable();
        }

        if (localStorage.getItem('currentUserToken') != 'undefined') {
            this.currentUserTokenSubject = new BehaviorSubject<UserToken>(
                JSON.parse(localStorage.getItem('currentUserToken') as string)
            );
            this.currentUserToken = this.currentUserTokenSubject.asObservable();
        }

        if (localStorage.getItem('currentUserRole') != 'undefined') {
            this.currentUserRoleSubject = new BehaviorSubject<UserToken>(
                JSON.parse(localStorage.getItem('currentUserRole') as string)
            );
            this.currentUserRole = this.currentUserRoleSubject.asObservable();
        }

        if (localStorage.getItem('currentUserRolePermissions') != 'undefined') {
            this.currentUserRolePermissionsSubject = new BehaviorSubject<UserToken>(
                JSON.parse(localStorage.getItem('currentUserRolePermissions') as string)
            );
            this.currentUserRolePermissions = this.currentUserRolePermissionsSubject.asObservable();
        }
    }

    // get current login user value;
    public get currentUserValue(): User {
        return this.currentUserSubject ? this.currentUserSubject.value : null;
    }

    // get current login user token;
    public get currentUserTokenValue(): UserToken {
        return this.currentUserTokenSubject.value;
    }

    // get current login user token;
    public get currentUserRoleValue(): any[] {
        return this.currentUserRoleSubject.value;
    }

    /**
     *
     *
     * @param {string} email
     * @param {string} password
     * @param {*} Ip
     * @returns data
     * @memberof AuthenticationService
     */
    login(email: string, password: string) {

        const url = `${environment.restUrl}/api/adminauth/login`;
        const postdata = { email: email, password: password };
        return this.http.post<any>(url, postdata).pipe(
            map((data: any) => {
                localStorage.setItem('currentUser', JSON.stringify(data.user_data));
                localStorage.setItem('currentUserToken', JSON.stringify(data.token_data));
                
                this.currentUserSubject.next(data.user_data);
                this.currentUserTokenSubject.next(data.token_data);
                
                return data;
            })
        );

    }



    

    /**
     *
     *
     * @param {string} email
     * @returns data
     * @memberof AuthenticationService
     */
    emailExistCheck(email: string) {

        const url = `${environment.restUrl}/api/auth/emailExist`;
        const postdata = { email: email };
        return this.http.post<any>(url, postdata).pipe(
            map((data: any) => {
                localStorage.setItem('currentUser', JSON.stringify(data.user_data));
                localStorage.setItem('currentUserToken', JSON.stringify(data.token_data));
                this.currentUserSubject.next(data.user_data);
                this.currentUserTokenSubject.next(data.token_data);

                return data;
            })
        );

    }


    /**
     * @returns data
     * @memberof AuthenticationService
     */
    logout() {
        const url = `${environment.restUrl}/api/adminauth/logout`;
        const postdata = { user_id: this.currentUserValue.id };
        return this.http.post<any>(url, postdata).pipe(
            map((data: any) => {
                localStorage.removeItem('currentUser');
                localStorage.removeItem('currentUserToken');
                this.currentUserSubject.next(null);
                this.currentUserTokenSubject.next(null);
                return data;
            })
        );

    }

    /**
   *
   *
   * @param {*} postdata
   * @returns data
   * @memberof AuthenticationService
   */
    register(postdata: any) {
        const url = `${environment.restUrl}/api/auth/register`;
        return this.http.post<any>(url, postdata).pipe(
            map(data => {
                return data;
            })
        );
    }

    /**
   * Forgots password
   * @param email
   * @returns  data
   */
    forgotPassword(email: string) {
        const url = `${environment.restUrl}/api/auth/forgot`;
        const postdata = { email: email };

        return this.http.post<any>(url, postdata).pipe(
            map(data => {
                return data;
            })
        );
    }

    /**
   *
   *
   * @param {string} token
   * @param {string} email
   * @returns data
   * @memberof AuthenticationService
   */
    activate(token: string, email: string) {
        const url = `${environment.restUrl}/api/auth/activateUser`;
        const postdata = { token: token, email: email };
        return this.http.post<any>(url, postdata).pipe(
            map(data => {
                if (data) {
                    localStorage.setItem('currentUser', JSON.stringify(data.user_data));
                    localStorage.setItem('currentUserToken', JSON.stringify(data.token_data));
                    localStorage.setItem('currentUserRole', JSON.stringify(data.user_data['role']));
                    this.currentUserSubject.next(data.user_data);
                    this.currentUserTokenSubject.next(data.token_data);
                }
                return data;
            })
        );
    }

    /**
   *
   *
   * @param {string} mail
   * @param {string} token
   * @returns data
   * @memberof AuthenticationService
   */
    resetpassword(mail: string, token: string, password: string) {
        const url = `${environment.restUrl}/api/auth/reset`;
        const postdata = { email: mail, token: token, password: password };

        return this.http.post<any>(url, postdata).pipe(
            map(data => {
                return data;
            })
        );
    }

}
