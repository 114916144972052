import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { BsDropdownModule } from "ngx-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { TagInputModule } from "ngx-chips";
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { AppComponent } from "./app.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AppRoutingModule } from "./app-routing.module";
import { ComponentsModule } from "./components/components.module";
import { FormProjectComponent } from './forms/form-project/form-project.component';
import { FormEmployeeComponent } from './forms/form-employee/form-employee.component';
import { FormDistrictComponent } from './forms/form-district/form-district.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { PermissionDeniedComponent } from './pages/permission-denied/permission-denied.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountsComponent } from './pages/accounts/accounts.component';
import { SubscriptionPlansComponent } from './pages/subscription-plans/subscription-plans.component';
import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    InfiniteScrollModule,
    NgxDatatableModule,
    GooglePlaceModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    AppRoutingModule,
    ToastrModule.forRoot(),
    CollapseModule.forRoot(),
    TagInputModule,
    NgbModule,
    CKEditorModule
  ],
  declarations: [
    AppComponent,
    AccountsComponent,
    AuthLayoutComponent,
    AdminLayoutComponent,
    FormProjectComponent,
    FormEmployeeComponent,
    FormDistrictComponent,
    PermissionDeniedComponent,
    SubscriptionPlansComponent,
    SubscriptionsComponent,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
